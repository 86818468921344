.why{
	position: relative;
	img{
		width: 35%;
		display: block;
		margin: 0 auto;
	}

	.col{
		position: absolute;
	  	top: 200px;
	  	width: 28%;
	  	padding: 15px 0px;
	  	font-size: 14px;

  		&-left{
  			left: 5%;
  		}

  		&-right{
  			right: 5%;
  		}

		.text{
			margin-bottom: 25%;
			text-align: center;

			p{
				margin: 0;
				margin-bottom: 10px;
				font-size: 23px;
				font-weight: 600;
			}

			span{
				font-size: 18px;
			}

		}

	}

	button{
		margin-top: 20px;
		padding: 20px 10px;
		display: block;
		width: 20%;
		margin: 20px auto;
		border-radius: 30px;
		background-color: #F07677;
		border: 0;
		color: #FFF;
		outline: none;
		font-size: 18px;
	}
}


@media (max-width: 500px) {
	.why{
		padding-left: 0!important;
		padding-right: 0!important;

		.title{
			font-size: 45px!important;
			padding: 0!important;
			margin-bottom: 20px!important;
		}

		img{
			width: 100%;
			margin-bottom: 20px;
		}

		.col{
			position: initial;
			width: 100%;
			padding: 0;

			.text{
				margin-bottom: 35px;
			}
		}

		button{
			width: auto;
			padding: 15px 13px;
		}
	}
}
.top{
	position: relative;
	padding-top: 40px;

	.label{
		position: absolute;
	  	top: 20px;
	  	right: 0;
	  	width: 23%;
	  	height: 22%;
	  	background-color: #FFF;
	  	padding: 1%;
	  	border-radius: 15px;

	  	span{
	  		display: block;
  			width: 100%;
  			text-align: center;
  			font-size: 150%;
	  	}

	  	button{
			padding: 3%;
			display: block;
			width: 100%;
			margin-top: 3%;
			border-radius: 20px;
			background-color: #F07677;
			border: 0;
			color: #FFF;
			outline: none;
			font-size: 16px;
		}
	}
	//background-image: url('/img/top.png');
	img{
		width: 100%;
		display: block;
	}
}

@media (max-width: 500px) {
	.top{
		img.w320{
			display: block!important;
		}

		.label{
			display: none;
		}

		.menu-icon{
			background-image: url('/img/menu.png');
			  position: absolute;
  top: 5%;
  left: 5%;
  width: 36px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: cover;
		}

		.menu-mobile{
			position: absolute;
			top: 0;
			width: 100%;
			background-color: #FFF;
			z-index: 2;
			display: none;

			&.show{
				display: block;
			}

			.close{
				text-align: center;
				font-size: 70px;
			}

			a{
				display: inline-block;
				width: 100%;
				vertical-align: top;
				font-size: 22px;
				text-align: center;
				color: #F07677;
				text-decoration: none;
				margin: 20px auto;
			}

			span{
		  		display: block;
	  			width: 100%;
	  			text-align: center;
	  			font-size: 150%;
		  	}

		  	button{
				padding: 3%;
				display: block;
				width: 60%;
				margin: 20px auto;
				border-radius: 20px;
				background-color: #F07677;
				border: 0;
				color: #FFF;
				outline: none;
				font-size: 16px;
			}
		}
	}
}
.bottom{
	background-color: #fff;
	line-height: 30px;
	padding-right: 10%!important;

	.phone{
		float: left;
		font-size: 22px;
	}

	.studio-link{
		float: right;
		display: block;
		background-image: url('/img/incolor.png');
		width: 73px;
		height: 30px;
	}

}
.review{
	background-image: url('/img/young_bg.png');
	padding: 0!important;
	padding-bottom: 5%!important;

	.title{
		display: block;
		text-align: center;
		margin: 0;
		padding: 3% 5%;
		font-size: 38px;
		font-family: 'MajesticX';
	}

	.owl-item{
		background-image: url('/img/young_bg.png');

		.images{
			width: 50%;
  			margin: 0 auto;

  			img{
  				width: 35%!important;
  				display: inline-block;
  				border-radius: 100%;
  				border: 10px solid #FFF;

  				&:first-child{
  					margin-right: -10%;
					z-index: 1;
					position: relative;
					margin-left: 15%;
  				}
  			}
		}

		.text{
			width: 60%;
			margin: 0 auto;
			text-align: center;
			margin-top: 20px;
			padding-bottom: 3%;
			font-size: 18px;
		}


	}

	.owl-theme .owl-dots .owl-dot span{
		width: 25px;
		height: 25px;
		margin: 2px 3px;
	}

	.owl-theme .owl-dots .owl-dot.active span{
		background-color: #F07677;
	}
}

@media (max-width: 500px) {
	.review{

		.title{
			width: auto;
			padding: 0;
		}

		.owl-item{
			.images{
				width: 90%;
			}

			.text{
				width: 70%;
			}
		}
	}
}
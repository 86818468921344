.fun{

	.gallery{
		a{
			display: inline-block;
			width: 32%;
			margin: 0.4%;
			vertical-align: top;
			height: 179px;
  			overflow: hidden;

			img{
				width: 100%;
			}
		}
	}

}

@media (max-width: 500px) {
	.fun{
		.gallery{
			a{
				display: inline-block;
				width: 100%;
				margin: 0.4%;
				vertical-align: top;
				height: 179px;
	  			overflow: hidden;

				img{
					width: 100%;
				}
			}
		}
	}
}
.wait{
	padding: 0!important;
	position: relative;;

	img{
		display: block;
		width: 100%;
	}

	.form{
		position: absolute;
		width: 33%;
		height: 88%;
		top: 9%;
		background-color: #fff8f3;
		right: 10%;
		box-shadow: 0 1px 1px;

		.header{
			padding: 5% 10%;
			text-align: center;
			background-color: #fff;
			font-size: 20px;
		}

		.body{
			margin-top: 3px;
  			border-top: 1px solid #CCC;
  			padding: 5% 7%;

  			label{
  				display: block;
  				text-align: center;
  				margin-bottom: 8px;
  				font-size: 20px;
  			}

  			input{
  				width: 94%;
				padding: 2%;
				margin-bottom: 7%;
				font-size: 20px;
  			}

  			select{
  				padding: 1% 2%;
  				background-color: #fff8f3;
  				font-size: 20px;

  				&.day{
  					width: 25%;
  				}

				&.month{
					width: 40%;
				}

				&.year{
					width: 30%;
				}
  			}

  			button{
  				margin-top: 20px;
				padding: 10px;
				display: block;
				width: 70%;
				margin: 20px auto;
				border-radius: 30px;
				background-color: #F07677;
				border: 0;
				color: #FFF;
				font-size: 18px;
  			}
		}
	}

}

@media (max-width: 500px) {
	.wait{

		button.w320{
			margin-top: 20px;
				padding: 15px 10px;
				display: block;
				width: 70%;
				margin: 20px auto;
				border-radius: 30px;
				background-color: #F07677;
				border: 0;
				color: #FFF;
				font-size: 18px;
				position: absolute;
				top: 22%;
				left: 15%;
		}

		.form{
			display: none;
		}

	}
}
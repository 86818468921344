.main{
	position: relative;

	.center{
		width: 46%;
  		margin: 0 auto;
  		display: block;
	}

	.col{
		position: absolute;
  		width: 24%;
  		top: 100px;
  		padding: 25px;
  		text-align: center;
  		font-size: 18px;

  		img{
  			width: 75%;
  			margin: 0 auto;
  			display: block;
  		}

  		span{
  			display: block;
  			margin-top: 15px;
  			margin-bottom: 35px;
  			font-size: 25px;
  		}

  		&-left{
  			left: 0;
  		}

  		&-right{
  			right: 0;

  			.label{
  				position: absolute;
  				font-family: MajesticX;
  				font-size: 44px;
  				color: #F07677;
  				margin: 0;
  				top: -12%;
  				left: -8%;
  			}

  			.arrow{
  				position: absolute;
  				top: -6%;
  				right: 53%;
  				width: 13%;
  			}
  		}

  		button{
  			padding: 10px 8px;
			display: block;
			width: 60%;
			margin: 0 auto;
			border-radius: 20px;
			background-color: #F07677;
			border: 0;
			color: #FFF;
			outline: none;
			font-size: 16px;
  		}
	}

}

@media (max-width: 500px) {
	.main{
		.center{
			width: 100%;
		}

		.col{
			position: initial;
			width: 100%;
			padding: 0;

			.label{
				position: initial;
				margin-top: 30px;
  				margin-bottom: 25px;
  				color: #000;
			}

			&-left{
				img{
					display: none;
				}
			}

			.arrow{
  				display: none;
  			}
		}
	}
}
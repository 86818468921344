body{
	background-image: url('/img/background.png');
	margin: 0;
	font-family: 'BarkentinaRegular'
}


.page{
	width: 90%;
	margin: 0 auto;
	min-width: 1090px;
  	max-width: 1920px;
  	overflow: hidden;

  	.w1000{
		display: block;
	}

	.w320{
		display: none;
	}

	.block{
		padding: 20px 5%;
		background-color: #fff;

		&:after {
		    content: ".";
		    display: block;
		    height: 0;
		    clear: both;
		    visibility: hidden;
		}

		.title{
			display: block;
			text-align: center;
			margin: 0;
			padding: 3% 5%;
			font-size: 50px;
			font-family: 'MajesticX';
		}
	}

	button{
		outline: none;
	}

	.modal{
		width: 450px;
		padding: 0;
		border-radius: 0;
		background-color: #fff8f3;

		&.tnx{
			img{
				width: 100%;
			}
		}

		.header{
			padding: 7% 12%;
			text-align: center;
			background-color: #fff;
			border-bottom: 1px solid #CCC;
			font-size: 20px;
		}

		.body{
			margin-top: 3px;
  			border-top: 1px solid #CCC;
  			padding: 11% 15%;

  			.form{
  				background-color: #fff8f3;
  			}

  			label{
  				display: block;
  				text-align: center;
  				margin-bottom: 8px;
  				font-size: 20px;
  			}

  			input{
  				width: 94%;
				padding: 2%;
				margin-bottom: 10%;
				font-size: 20px;
  			}

  			select{
  				padding: 1% 2%;
  				background-color: #fff8f3;
  				font-size: 20px;

  				&.day{
  					width: 25%;
  				}

				&.month{
					width: 40%;
				}

				&.year{
					width: 28%;
				}
  			}

  			button{
  				margin-top: 20px;
				padding: 12px;
				display: block;
				width: 70%;
				margin: 0 auto;
				border-radius: 30px;
				background-color: #F07677;
				border: 0;
				color: #FFF;
				font-size: 18px;
				margin-top: 10%;
  			}
		}

	}

	.modal-wide{
		width: 850px;
		background-image: url('/img/numbers.png');

		.item{
			width: 50%;
			float: left;

			&.form{
				background-color: #FFF8F3;
			}
		}

		.text{
			.title{
				padding: 10px 35px;
  				text-align: center;
  				font-size: 20px;
  				position: relative;
  				  margin-bottom: 20px;

  				&:after{
  					content: '';
  					position: absolute;
  					  width: 25%;
					  bottom: -15px;
					  border-bottom: 10px solid #F07677;
					  left: 40%;
  				}
			}

			span{
				  padding: 25px 40px;
				  margin-top: 50px;
				  display: block;
				  text-align: center;
				  font-size: 16px;
				  line-height: 24px;
			}

			.photo{
				padding: 0 40px;
  				box-sizing: border-box;
  				margin-top: 5%;

  				img{
  					border-radius: 100%;
  					width: 40%;
  				}

  				p{
  					float: right;
  					line-height: 120px;
  					margin: 0;
  					font-size: 22px;
  					margin-right: 5%;
  				}
			}
		}
	}
}


@media (max-width: 500px) {
	.page{
		width: 100%;
		min-width: 320px;

		.w320{
			display: initial;
		}

		.w1000{
			display: none;
		}

		.modal{
			width: 320px;

			
			.body{
				padding: 6%;
			}
			

			select{
				padding: 1% 2%!important;
				//width: auto!important;
			}
		}

		.modal-wide{
			width: 320px;

			.item{
				width: 100%;
				display: none;
			}

			.form{
				display: block;
			}

			.photo{
				display: none;
			}
		}
	}
}


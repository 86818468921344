/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";
@import "../../bower_components/baguettebox.js/dist/baguetteBox.min.css";
@import "../../bower_components/OwlCarousel2/owl.carousel.css";
@import "../../bower_components/OwlCarousel2/owl.theme.css";
@import "../../bower_components/modal/jquery.modal.css";


/*
* Custom
*/
@import "partials/variable";
@import "partials/fonts";
@import "partials/page";
@import "partials/top";
@import "partials/menu";
@import "partials/main";
@import "partials/numbers";
@import "partials/why";
@import "partials/fun";
@import "partials/review";
@import "partials/ready";
@import "partials/wait";
@import "partials/bottom";
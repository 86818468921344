.numbers{
	background-image: url('/img/numbers.png');

	.body{
		padding: 15px;
  		font-size: 0;
  		padding-top: 0;

		.item{
			width: 25%;
  			display: inline-block;
  			font-size: 16px;
  			text-align: center;
  			font-family: MajesticX;
  			vertical-align: top;

  			p{
  				font-size: 60px;
  				margin: 0;
  				color: #F07677;
  			}

  			span{
  				font-size: 38px;
  			}
		}

		button{
			padding: 20px 15px;
			display: block;
			width: 25%;
			margin: 0 auto;
			margin-top: 30px;
			border-radius: 20px;
			background-color: #F07677;
			border: 0;
			color: #FFF;
			outline: none;
			font-size: 18px;
			margin-bottom: -15px;
		}

	}
}


@media (max-width: 500px) {
	.numbers{

		.title{
			margin-left: -5%!important;
  			//width: 100%;
  			padding: 0!important;
  			margin-bottom: 25px!important;
		}

		.body{
			.item{
				width: 100%;
				//margin-left: -5%;
				margin-bottom: 20px;

				span{
					font-size: 32px;
				}
			}

			button{
				padding: 15px 13px;
				margin: 0 auto;
				margin-right: 10%;
				width: auto;
				display: block;
				margin-top: 20px;

			}
		}
	}
}
.menu{
	padding: 1% 15%;
	font-size: 0;
	background-color: #fff;

	a{
		display: inline-block;
		width: 20%;
		vertical-align: top;
		font-size: 20px;
		text-align: center;
		color: #F07677;
		text-decoration: none;
	}
}

@media (max-width: 500px) {
	.menu{
		display: none;
	}
}
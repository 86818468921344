@font-face {
    font-family: "BarkentinaRegular";
    src: url("../fonts/BarkentinaRegular/BarkentinaRegular.eot");
    src: url("../fonts/BarkentinaRegular/BarkentinaRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/BarkentinaRegular/BarkentinaRegular.woff") format("woff"),
    url("../fonts/BarkentinaRegular/BarkentinaRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
  font-family: 'MajesticX';
  src: url('../fonts/MajesticX/MajesticX.eot');
  src: url('../fonts/MajesticX/MajesticX.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MajesticX/MajesticX.woff') format('woff'),
       url('../fonts/MajesticX/MajesticX.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MajesticY';
  src: url('../fonts/MajesticY/MajesticY.eot');
  src: url('../fonts/MajesticY/MajesticY.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MajesticY/MajesticY.woff') format('woff'),
       url('../fonts/MajesticY/MajesticY.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}
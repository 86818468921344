.ready{

	padding: 0!important;
	position: relative;

	img{
		display: block;
		width: 100%;
	}

	.form{
		position: absolute;
		width: 33%;
		background-color: #fff8f3;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.72);
		right: 5%;
		height: 81%;
		top: 9.5%;

		.header{
			padding: 7% 5%;
			text-align: center;
			background-color: #fff;
			font-size: 20px;
		}

		.body{
			margin-top: 3px;
  			border-top: 1px solid #CCC;
  			padding: 5% 7%;

  			label{
  				display: block;
  				text-align: center;
  				margin-bottom: 8px;
  				font-size: 20px;
  			}

  			input{
  				width: 94%;
				padding: 5px;
				margin-bottom: 7%;
				font-size: 20px;
  			}

  			select{
  				padding: 1% 3%;
  				background-color: #fff8f3;
  				font-size: 20px;

  				&.day{
  					width: 25%;
  				}

				&.month{
					width: 40%;
				}

				&.year{
					width: 30%;
				}

  			}

  			button{
  				margin-top: 40px;
				padding: 4% 3%;
				display: block;
				width: 60%;
				margin: 30px auto;
				border-radius: 30px;
				background-color: #F07677;
				border: 0;
				color: #FFF;
				font-size: 18px;
  			}
		}
	}
}

@media (max-width: 500px) {
	.ready{
		.form{
			width: 96%;
			margin: 0 auto;
			left: 2%;
			top: 33%;
			height: 58%;
		}
	}
}